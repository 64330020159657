import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { floor, subtract } from 'lodash';
import DownloadGreen from 'assets/icons/download_green.svg';
import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import BookImage from './BookImage';
import { config } from 'config';

interface OrderSummaryProps {
  title: string;
  upsellsKeys: string[];
  bundlePhoto: { [key: string]: number };
  isWhite?: boolean;
}

const Container = styled.div<{ isWhite: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 1rem;
  gap: 4rem;
  background-color: ${({ isWhite }) => (isWhite ? '#fff' : '#f3f3f8')};
  @media ${tablet} {
    gap: 3rem;
    padding: 3rem 1rem;
  }
`;

const Heading = styled(Text)`
  color: #1c1c28;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.04rem;
  text-align: center;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 130%;
    letter-spacing: -0.024rem;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 872px;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 1.5rem;
  width: 100%;
  padding-bottom: 2rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
    gap: 0.5rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  justify-content: end;
  gap: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.light100};

  padding-top: 2rem;
  @media ${tablet} {
    justify-content: space-between;
    padding-top: 0.5rem;
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  @media ${tablet} {
    gap: 5px;
  }
`;

const UpsellDownload = styled.a`
  cursor: pointer;
  color: #00a676 !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 140%;
  text-decoration: underline !important;
  scroll-behavior: smooth;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 18px;
`;

const StyledImg = styled(DynamicImage)`
  width: 50px;
  @media ${tablet} {
    width: 33px;
  }
`;

const ItalicText = styled(Text)`
  font-style: italic;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
`;

const Subtitle = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4375rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
  }
`;

const ScrollToAppWrapper = styled.div`
  color: #00a676;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 140%;
  text-decoration-line: underline;
  scroll-behavior: smooth;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
  }
`;

const ProductTitle = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: normal;
  }
`;

const BabyName = styled.p<{ bookColor: string }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-family: 'Brandon Grotesque', sans-serif;
  font-size: 0.3rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: 0.9rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  white-space: nowrap;
  @media ${tablet} {
    font-size: 0.2rem;
    top: 0.55rem;
  }
`;

const BottomNames = styled.p<{ bookColor: string }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-size: 0.14rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  bottom: 0.5rem;
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  font-family: 'Brandon Grotesque', sans-serif;
  z-index: 3;
  @media ${tablet} {
    font-size: 0.075rem;
    bottom: 0.3rem;
  }
`;

const EbookContainer = styled.div`
  display: flex;
  padding: 0.05rem 0.31794rem;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  bottom: -3px;
  border-radius: 0.27181rem;
  background: rgb(243, 101, 35);
  z-index: 3;
  right: 50%;
  transform: translateX(50%);
  color: rgb(250, 241, 235);
  text-align: center;
  font-size: 0.2rem;
  font-weight: 800;
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${tablet} {
    max-width: 2.1875rem;
  }
`;

const UpsellTitle = styled.p`
  color: #555770;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01238rem;
  padding-left: 5.06rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 0;
    padding-left: 2.6875rem;
  }
`;

const MultipleUpsellLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Total = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8125rem;
  @media ${tablet} {
    font-size: 1.125rem;
    line-height: 140%;
  }
`;

const Price = styled.p`
  color: #000;
  text-align: right;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Free = styled.p`
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: #00a676;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 100%;
  @media${tablet} {
    font-size: 0.6875rem;
  }
`;
const Prices = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

const OldPrice = styled.div`
  color: #717171;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: line-through;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const AppDownload = styled(UpsellDownload)<{ isReadyToDownload: boolean }>`
  cursor: ${({ isReadyToDownload }) =>
    isReadyToDownload ? 'pointer' : 'default'};
  opacity: ${({ isReadyToDownload }) => (isReadyToDownload ? '1' : '0.6')};
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  isWhite,
  upsellsKeys,
  bundlePhoto,
}) => {
  const { isTablet } = useQuery();
  const products = useSelector(
    (state: AppState) => state.funnel.products,
  ) as Product[];

  const {
    selected_plans,
    upsell_products,
    selected_plans_options,
    quiz_answers,
  } = useSelector((state: AppState) => state.user);

  const boughtUpsell = upsell_products.map(item => item.key);

  const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const getBundlePhoto = () => {
    let result = '';
    sortedUpsells?.forEach(str => {
      if (bundlePhoto[str] !== undefined) {
        result += bundlePhoto[str].toString();
      }
    });
    if (result === '') {
      return '1234';
    }
    return result;
  };

  const selectedProduct = getLocalisedProduct(selected_plans[0]);
  const currency = selectedProduct?.currencyId;
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const upsellsPrice = localisedUpsellProducts?.reduce((sum, p) => {
    if (p?.hasTrial) {
      return sum + parseFloat(p.discountedTrialPrice || '0');
    }

    return sum + parseFloat(p?.finalPrice || '0');
  }, 0);

  const totalPrice = selected_plans.reduce((prevValue, product) => {
    const productPrice = product.prices.filter(
      priceItem => priceItem.currency_id == currency,
    )[0]?.final_price;

    return prevValue + productPrice;
  }, 0);

  const totalPriceUpsell = upsell_products.reduce((prevValue, product) => {
    const productPrice = product.prices.filter(
      priceItem => priceItem.currency_id == currency,
    )[0]?.final_price;
    return prevValue + productPrice;
  }, 0);

  const resolvePageTextContent = product => {
    let summaryProduct;

    if (product.key.includes('discounted')) {
      const productKey = product.key.replace(/-discounted(?=-|$)/, '');
      const newProduct = products.find(item => item.key === productKey);
      summaryProduct = newProduct ? newProduct : product;
    } else if (product.key.includes('free')) {
      const productKey = product.key.replace(/-free$/, '');
      const newProduct = products.find(item => item.key === productKey);
      summaryProduct = newProduct ? newProduct : product;
    } else {
      summaryProduct = product;
    }

    const priceToShow = product?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.final_price;

    const oldPrice = product?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.price;

    const priceToShowDiscounted = summaryProduct?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.final_price;
    const oldPriceDiscounted = summaryProduct?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.price;

    // const trialAmount = product?.has_trials
    //   ? product?.trials.filter(
    //       priceItem => priceItem.currency_id === currency,
    //     )[0].amount
    //   : undefined;

    const trialAmount = product?.has_trials
      ? product.trials.filter(priceItem => priceItem.currency === currency)[0]
          ?.amount
      : null;

    const isEbook =
      product.type === 'digital' &&
      product.is_subscription === false &&
      product.is_upsell === false;
    const scrollHeight = isTablet ? 350 : 600;
    if (
      product?.is_subscription &&
      product?.is_upsell &&
      product?.key.includes('upsell-nordastro-1-month')
    ) {
      return {
        title: product?.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() => window.open(config.WEB_APP_URL, '_system')}
          >
            <DownloadGreen />
            Download here
          </ScrollToAppWrapper>
        ),
        priceToShow,
        productImg: 'success/subscriptionProductImg.png',
      };
    }

    if (
      product?.is_subscription &&
      product?.is_upsell &&
      product?.key.includes('nordastro_upsell_') &&
      product?.key.includes('_sub_trial')
    ) {
      const finalPrice = trialAmount !== null ? trialAmount : priceToShow ?? 0;

      return {
        title: product?.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() => window.open(config.WEB_APP_URL, '_system')}
          >
            <DownloadGreen />
            Download here
          </ScrollToAppWrapper>
        ),
        oldPrice,
        priceToShow: finalPrice === 0 ? 'FREE' : finalPrice,
        productImg: 'success/subscriptionProductImg.png',
      };
    }

    if (product?.is_subscription && product.is_upsell) {
      return {
        title: product.name,
        priceToShow,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    if (
      product?.is_subscription &&
      !selected_plans_options.paperBook &&
      !selected_plans_options.ebookCheck
    ) {
      return {
        title: product.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() =>
              window.scrollTo({ top: scrollHeight, behavior: 'smooth' })
            }
          >
            <DownloadGreen />
            {product?.description}
          </ScrollToAppWrapper>
        ),
        priceToShow,
        oldPrice,
        productImg: 'success/subscriptionProductImg.png',
        isEbook: false,
      };
    }
    if (
      product?.is_subscription &&
      (selected_plans_options.paperBook || selected_plans_options.ebookCheck)
    ) {
      return {
        title: product.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() => window.open(config.WEB_APP_URL, '_system')}
          >
            <DownloadGreen />
            Download here
          </ScrollToAppWrapper>
        ),
        priceToShow: 'FREE',
        oldPrice,
        productImg: 'success/subscriptionProductImg.png',
      };
    }

    if (product.type === 'physical' && !selected_plans_options.subContainer) {
      return {
        title: product.name,
        subtitle: product.description,
        priceToShow,
        oldPrice,
        productImg: `fake-books-covers/${quiz_answers.color}.png`,
      };
    }
    if (product.type === 'physical' && selected_plans_options.subContainer) {
      return {
        title: summaryProduct.name,
        subtitle: summaryProduct.description,
        priceToShowDiscounted,
        oldPriceDiscounted,
        productImg: `fake-books-covers/${quiz_answers.color}.png`,
      };
    }

    if (product.key.includes('nordastro-book-bundle')) {
      const planKeys = upsell_products.map(up => up.key);
      return {
        title: product.name,
        subtitle: (
          <MultipleUpsellLinkContainer>
            {planKeys.includes('upsell-mastering-face-reading') ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fmastering-face-reading-the-complete-guide.pdf?alt=media&token=c64d80f1-2438-4bcd-8e75-7034cdd0c70a'
                }
                target="_blank"
              >
                <DownloadGreen />
                Mastering Face Reading
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-money-attraction-guide') ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fmagnetize-money.pdf?alt=media&token=fdec2a0c-29e9-4fba-b2e1-441920eb36c2'
                }
                target="_blank"
              >
                <DownloadGreen />
                Money Attraction Guide
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-dream-dictionary') ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fdream-dictionary.pdf?alt=media&token=1be4aaed-d0a5-48e1-90b2-b1d418b46c3a'
                }
                target="_blank"
              >
                <DownloadGreen />
                Dream Dictionary
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-manifest-dream-life') ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fhow-to-manifest-your-dream-life.pdf?alt=media&token=761af7bb-2f3f-4da8-b908-d1c768f54921'
                }
                target="_blank"
              >
                <DownloadGreen />
                Manifestation Workbook
              </UpsellDownload>
            )}
          </MultipleUpsellLinkContainer>
        ),
        priceToShow,
        productImg: `upsell/bundle/bundle-${getBundlePhoto()}.png`,
      };
    }
    if (product.key.includes('upsell-mastering-face-reading')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fmastering-face-reading-the-complete-guide.pdf?alt=media&token=c64d80f1-2438-4bcd-8e75-7034cdd0c70a'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/mastering-face-reading/card.png',
      };
    }
    if (product.key.includes('upsell-money-attraction-guide')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fmagnetize-money.pdf?alt=media&token=fdec2a0c-29e9-4fba-b2e1-441920eb36c2'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/money-attraction-guide/card.png',
      };
    }
    if (product.key.includes('upsell-dream-dictionary')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fdream-dictionary.pdf?alt=media&token=1be4aaed-d0a5-48e1-90b2-b1d418b46c3a'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/dream-dictionary/card.png',
      };
    }
    if (product.key.includes('upsell-manifest-dream-life')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/nordastro-app-prod.appspot.com/o/upsells%2Fhow-to-manifest-your-dream-life.pdf?alt=media&token=761af7bb-2f3f-4da8-b908-d1c768f54921'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/manifest-dream-life/card.png',
      };
    }
    if (
      (product.key.includes('e-book-free') && product.is_upsell) ||
      (product.key.includes('upsell-e-book-express-shipping') &&
        product.is_upsell) ||
      (product.key.includes('hardcover') && product.is_upsell)
    ) {
      return {
        title: product.name,
        priceToShow,
        is_upsell: product?.is_upsell,
        subtitle: product?.description,
      };
    }
    if (
      isEbook &&
      !selected_plans_options.paperBook &&
      !selected_plans_options.subContainer
    ) {
      return {
        title: product.name,
        priceToShow,
        oldPrice,
        productImg: `fake-books-covers/${quiz_answers.color}.png`,
        isEbook: true,
        subtitle: quiz_answers?.ebookUrl ? (
          <AppDownload
            href={quiz_answers?.ebookUrl}
            target="_blank"
            isReadyToDownload
          >
            <DownloadGreen />
            Download here
          </AppDownload>
        ) : (
          <AppDownload isReadyToDownload={false}>
            <DownloadGreen />
            Download here
          </AppDownload>
        ),
      };
    }
    if (isEbook && selected_plans_options.paperBook) {
      return {
        title: summaryProduct.name,
        priceToShowDiscounted: 'FREE',
        oldPriceDiscounted,
        productImg: `fake-books-covers/${quiz_answers.color}.png`,
        isEbook: true,
        subtitle: quiz_answers?.ebookUrl ? (
          <AppDownload
            href={quiz_answers?.ebookUrl}
            target="_blank"
            isReadyToDownload
          >
            <DownloadGreen />
            Download here
          </AppDownload>
        ) : (
          <AppDownload isReadyToDownload={false}>
            <DownloadGreen />
            Download here
          </AppDownload>
        ),
      };
    }
    if (isEbook && selected_plans_options.subContainer) {
      return {
        title: summaryProduct.name,
        priceToShowDiscounted,
        oldPriceDiscounted,
        productImg: `fake-books-covers/${quiz_answers.color}.png`,
        isEbook: true,
        subtitle: quiz_answers?.ebookUrl ? (
          <AppDownload
            href={quiz_answers?.ebookUrl}
            target="_blank"
            isReadyToDownload
          >
            <DownloadGreen />
            Download here
          </AppDownload>
        ) : (
          <AppDownload isReadyToDownload={false}>
            <DownloadGreen />
            Download here
          </AppDownload>
        ),
      };
    }
  };

  const subscriptionsSummary = [];
  const paperBookSummary = [];
  const eBookSummary = [];
  const otherBundles = [];

  const allProducts = [...(selected_plans || []), ...(upsell_products || [])];

  allProducts?.forEach(product => {
    if (product.is_subscription) {
      subscriptionsSummary.push({ ...product, hasImage: true });
      return;
    }
    if (product.type === 'physical' || product.key.includes('hardcover')) {
      paperBookSummary.push({ ...product, hasImage: true });
      return;
    }
    if (
      product.key.includes('e-book-free') ||
      product.key.includes('upsell-e-book-express-shipping') ||
      product.key.includes('e-book-downsell') ||
      product.key.includes('e-book')
    ) {
      eBookSummary.push({ ...product, hasImage: true, isEbook: true });
      return;
    } else {
      otherBundles.push({ ...product, hasImage: true, isEbook: true });
      return;
    }
  });

  const groupedProductsArray = [
    ...(subscriptionsSummary || []),
    ...(paperBookSummary || []),
    ...(eBookSummary || []),
    ...(otherBundles || []),
  ];

  const hasBoughtLifeTime = groupedProductsArray.find(
    item => item.key === 'upsell-nordastro-lifetime',
  );

  return (
    <Container isWhite={isWhite}>
      <Heading>{title}</Heading>
      <SummaryContainer>
        {groupedProductsArray?.map(item => {
          const {
            title,
            subtitle,
            priceToShow,
            priceToShowDiscounted,
            productImg,
            isEbook,
            oldPrice,
            oldPriceDiscounted,
          } = resolvePageTextContent(item);

          const hideOldPrice = item.key.includes('trial')
            ? false
            : item.is_upsell === true ||
              (!selected_plans_options.paperBook &&
                !selected_plans_options.ebookCheck &&
                selected_plans_options.subContainer) ||
              (!selected_plans_options.paperBook &&
                selected_plans_options.ebookCheck &&
                !selected_plans_options.subContainer) ||
              (!selected_plans_options.paperBook &&
                selected_plans_options.ebookCheck &&
                selected_plans_options.subContainer);

          const isPaperback = item.type === 'physical';

          return (
            <OrderItemContainer key={title}>
              {productImg && (
                <BookContainer>
                  <DynamicImage
                    width={isTablet ? '2.125rem' : '3.5rem'}
                    src={productImg}
                    alt="product"
                  />
                </BookContainer>
              )}
              {productImg ? (
                <ProductInfoContainer>
                  <PriceContainer>
                    <ProductTitle>{title}</ProductTitle>
                    {item.key.includes('nordastro-1-month') &&
                    (selected_plans_options.paperBook ||
                      selected_plans_options.ebookCheck) &&
                    hasBoughtLifeTime ? null : (
                      <Prices>
                        {!hideOldPrice && (
                          <OldPrice>
                            {selectedProduct?.currency +
                              (oldPrice
                                ? oldPrice
                                : oldPriceDiscounted
                              ).toFixed(2)}
                          </OldPrice>
                        )}
                        {(priceToShow || priceToShowDiscounted) === 'FREE' ? (
                          <Free>FREE</Free>
                        ) : (
                          <Price>
                            {selectedProduct?.currency +
                              (priceToShow
                                ? priceToShow
                                : priceToShowDiscounted
                              ).toFixed(2)}
                          </Price>
                        )}
                      </Prices>
                    )}
                  </PriceContainer>

                  <Subtitle>{subtitle}</Subtitle>
                </ProductInfoContainer>
              ) : (
                <ProductInfoContainer>
                  <PriceContainer>
                    <UpsellTitle>{title}</UpsellTitle>
                    <Price>
                      {selectedProduct?.currency + priceToShow.toFixed(2)}
                    </Price>
                  </PriceContainer>

                  <Subtitle>{subtitle}</Subtitle>
                </ProductInfoContainer>
              )}
            </OrderItemContainer>
          );
        })}
        <TotalItemContainer>
          <Total>Total:</Total>
          <Total>
            {selectedProduct?.currency}
            {(
              Math.round(Number(totalPrice + upsellsPrice) * 100) / 100
            ).toFixed(2)}
          </Total>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;
