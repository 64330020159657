import { Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';

interface NumberedListProps {
  title: string;
  list: string[];
  mainColor: string;
  more: string;
}

const Container = styled.div`
  background-color: #fff;
  padding: 4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media ${tablet} {
    padding: 0;
  }
`;

const TextStyled = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.048rem;
  max-width: 48rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.036rem;
    padding-bottom: 0.75rem;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 35rem;
  width: 100%;
  align-items: flex-start;
  gap: 0.75rem;
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media ${tablet} {
  }
`;

const NumberContainer = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Number = styled(Text)`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.section`
  background-color: #fefbeb;
  display: flex;
  padding: 3rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 71.25rem;
  width: 100%;
  border-radius: 0.5rem;
  @media ${tablet} {
    gap: 0.75rem;
    padding: 2.25rem 1rem;
    border-radius: 0;
  }
`;

const NumberText = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const More = styled(Text)`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.012rem;
`;

const NumberedList2: FC<NumberedListProps> = ({
  title,
  mainColor,
  list,
  more,
}) => {
  const renderList = (text: string, index: number) => (
    <ListItemContainer key={index}>
      <NumberContainer bgColor={mainColor}>
        <Number>{index + 1}</Number>
      </NumberContainer>
      <NumberText>{text}</NumberText>
    </ListItemContainer>
  );

  return (
    <Container>
      <Card>
        <TextStyled>{title}</TextStyled>
        <ListContainer>{list.map(renderList)}</ListContainer>
        <More>{more}</More>
      </Card>
    </Container>
  );
};

export default NumberedList2;
